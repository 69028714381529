// extracted by mini-css-extract-plugin
export var carouselContainer = "L_sP d_w d_H d_bf d_Z";
export var carouselContainerCards = "L_wX L_sP d_w d_H d_bf d_Z";
export var carouselContainerSides = "L_wY d_w d_H d_Z";
export var prevCarouselItem = "L_wZ d_w d_H d_0 d_k";
export var prevCarouselItemL = "L_w0 L_wZ d_w d_H d_0 d_k";
export var moveInFromLeft = "L_w1";
export var prevCarouselItemR = "L_w2 L_wZ d_w d_H d_0 d_k";
export var moveInFromRight = "L_w3";
export var selectedCarouselItem = "L_w4 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "L_w5 L_w4 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "L_w6 L_w4 d_w d_H d_Z d_bf";
export var nextCarouselItem = "L_w7 d_w d_H d_0 d_k";
export var nextCarouselItemL = "L_w8 L_w7 d_w d_H d_0 d_k";
export var nextCarouselItemR = "L_w9 L_w7 d_w d_H d_0 d_k";
export var arrowContainer = "L_xb d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "L_xc L_xb d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "L_xd L_xc L_xb d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "L_xf L_xb d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "L_xg";
export var nextArrowContainerHidden = "L_xh L_xf L_xb d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "L_kG d_0";
export var prevArrow = "L_xj L_kG d_0";
export var nextArrow = "L_xk L_kG d_0";
export var carouselIndicatorContainer = "L_xl d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "L_xm d_w d_bz d_bF";
export var carouselText = "L_xn d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "L_xp L_xn d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "L_xq d_b7";
export var carouselIndicator = "L_xr L_xq d_b7";
export var carouselIndicatorSelected = "L_xs L_xq d_b7";
export var arrowsContainerTopRight = "L_xt d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "L_xv d_0 d_bl d_bC";
export var arrowsContainerSides = "L_xw d_0 d_bl d_bC";
export var smallArrowsBase = "L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "L_xy L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "L_xz L_xy L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "L_xB L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "L_xC L_xB L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "L_n9";
export var iconSmall = "L_xD";
export var multipleWrapper = "L_xF d_bC d_bF d_bf";
export var multipleImage = "L_xG d_bC";
export var sidesPrevContainer = "L_xH L_xy L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "L_xJ L_xy L_xx d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";