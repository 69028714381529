// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "D_vY d_gv d_cs";
export var heroHeaderCenter = "D_gw d_gw d_cs d_dw";
export var heroHeaderRight = "D_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "D_vZ d_gr d_cw";
export var heroParagraphCenter = "D_gs d_gs d_cw d_dw";
export var heroParagraphRight = "D_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "D_v0 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "D_v1 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "D_v2 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "D_v3 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "D_v4 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "D_t1 y_t1";
export var heroExceptionNormal = "D_t2 y_t2";
export var heroExceptionLarge = "D_t3 y_t3";
export var Title1Small = "D_tL y_tL y_tf y_tg";
export var Title1Normal = "D_tM y_tM y_tf y_th";
export var Title1Large = "D_tN y_tN y_tf y_tj";
export var BodySmall = "D_tW y_tW y_tf y_ty";
export var BodyNormal = "D_tX y_tX y_tf y_tz";
export var BodyLarge = "D_tY y_tY y_tf y_tB";