// extracted by mini-css-extract-plugin
export var noMargin = "v_sM d_cc";
export var carouselCol = "v_sN d_cD";
export var carouselWrapper = "v_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "v_k7 d_k7 d_H d_w";
export var carouselContainer = "v_sP d_cD";
export var carouselContainerFull = "v_sQ d_dT";
export var carouselImg = "v_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "v_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "v_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "v_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "v_kZ d_kZ";
export var imageAltSmall = "v_sR d_k4";
export var imageAltNormal = "v_sS d_k4";
export var imageAltLarge = "v_sT d_k4";
export var imageTextSmall = "v_sV d_k5";
export var imageTextNormal = "v_sW d_k5";
export var imageTextLarge = "v_sX d_k5";