// extracted by mini-css-extract-plugin
export var customText = "x_s9 d_dv d_cs d_cg";
export var videoIframeStyle = "x_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "x_rh d_cs d_cg d_Z";
export var customRow = "x_pb d_bD d_bf";
export var quoteWrapper = "x_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "x_nX d_H";
export var masonryImageWrapper = "x_n3";
export var title = "x_sy";
export var Title3Small = "x_tb y_tb y_tf y_tn";
export var Title3Normal = "x_tc y_tc y_tf y_tp";
export var Title3Large = "x_td y_td y_tf y_tq";