// extracted by mini-css-extract-plugin
export var alignDiscLeft = "z_vr d_fp d_bG d_dv";
export var alignLeft = "z_rd d_fp d_bG d_dv";
export var alignDiscCenter = "z_vs d_fq d_bD d_dw";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignDiscRight = "z_vt d_fr d_bH d_dx";
export var alignRight = "z_rf d_fr d_bH d_dx";
export var footerContainer = "z_vv d_dW d_bW";
export var footerContainerFull = "z_vw d_dT d_bW";
export var footerHeader = "z_kf d_kf";
export var footerTextWrapper = "z_vx d_w";
export var footerDisclaimerWrapper = "z_km d_km d_ck";
export var badgeWrapper = "z_vy d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "z_vz d_bz d_bJ d_bN d_bL";
export var wide = "z_vB d_cy";
export var right = "z_vC d_bK";
export var line = "z_fk d_fl d_cv";
export var badgeDisclaimer = "z_vD d_bC d_bP d_bJ";
export var badgeContainer = "z_vF d_bz d_bH d_bP";
export var badge = "z_vG";
export var padding = "z_vH d_c7";
export var end = "z_vJ d_bH";
export var linkWrapper = "z_vK d_dB";
export var link = "z_mC d_dB";
export var colWrapper = "z_vL d_cx";
export var consent = "z_f d_f d_bC d_bP";
export var disclaimer = "z_vM d_bz d_bJ";
export var media = "z_vN d_bx d_dy";
export var itemRight = "z_vP";
export var itemLeft = "z_vQ";
export var itemCenter = "z_vR";
export var exceptionWeight = "z_vS y_tH";