// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_wc d_bD";
export var storyRowWrapper = "G_hx d_hx d_bK";
export var storyLeftWrapper = "G_wd d_bz d_bP";
export var storyWrapperFull = "G_wf d_cD";
export var storyWrapperFullLeft = "G_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "G_mv d_hy";
export var storyLeftWrapperCenter = "G_wg d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "G_wh d_hF";
export var storyHeader = "G_wj d_hD d_w d_cs";
export var storyHeaderCenter = "G_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "G_hB d_hB d_by d_dw";
export var storyBtnWrapper = "G_wk d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "G_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "G_rj d_fg d_Z";
export var imageWrapperFull = "G_wl d_w d_H d_bf d_Z";
export var SubtitleSmall = "G_pd y_pd y_tf y_tr";
export var SubtitleNormal = "G_pf y_pf y_tf y_ts";
export var SubtitleLarge = "G_pg y_pg y_tf y_tt";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";